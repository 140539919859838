import { Component, OnInit } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public ngxScrollToOffset: number;
  constructor() {
    this.ngxScrollToOffset = 300;
  }

  ngOnInit() {
    window.scrollTo(0, 0)
  }

}
