import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClientModule  } from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { PhoenixComponent } from './phoenix/phoenix.component';
import { HomeComponent } from './home/home.component';
import { HomeComponent2 } from './home/home2.component';
import { HomeComponent3 } from './home/home3.component';
import { HomeComponent4 } from './home/home4.component';
import { SmsComponent } from './sms/sms.component';
import { CloudlinkComponent } from './cloudlink/cloudlink.component';
import { HeaderBlockComponent } from './header-block/header-block.component';
const routes=[
  {
  path:'item', component:HomeComponent
}]
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    PhoenixComponent,
    HomeComponent,
    HomeComponent2,
    HomeComponent3,
    HomeComponent4,
    SmsComponent,
    CloudlinkComponent,
    HeaderBlockComponent
  ],
  imports: [
     BrowserModule,
     AppRoutingModule,
     HttpClientModule,
     FormsModule,
     ScrollToModule.forRoot(),
     RouterModule.forRoot(routes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
