import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
@Component({
  selector: 'app-home4',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent4 implements OnInit {
  public ngxScrollToOffset: number;
  @ViewChild('allServices', {static: false}) elementView: ElementRef;
  viewHeight: number;
  constructor() {
    this.ngxScrollToOffset = 300;
  }
  ngOnInit() {}
  ngAfterViewInit() {
    this.viewHeight = this.elementView.nativeElement.offsetHeight;
    window.scrollTo(0, this.viewHeight-74);
  }

}
