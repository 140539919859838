import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhoenixComponent } from './phoenix/phoenix.component';
import { HomeComponent } from './home/home.component';
import { HomeComponent2 } from './home/home2.component';
import { HomeComponent3 } from './home/home3.component';
import { HomeComponent4 } from './home/home4.component';
import { SmsComponent } from './sms/sms.component';
import { CloudlinkComponent } from './cloudlink/cloudlink.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'phoenix', component: PhoenixComponent },
  { path: 'sms', component: SmsComponent },
  { path: 'cloudlink', component: CloudlinkComponent },
  { path: 'about', component: HomeComponent2 },
  { path: 'services', component: HomeComponent3 },
  { path: 'contact', component: HomeComponent4 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
