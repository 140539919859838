import { Component, HostListener,OnInit, Inject } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-cloudlink',
  templateUrl: './cloudlink.component.html',
  styleUrls: ['./cloudlink.component.scss']
})
export class CloudlinkComponent implements OnInit {
  constructor(@Inject(DOCUMENT) document) {}
  cust: boolean = false;
  data: boolean = false;
  rewards: boolean = false;
  def: boolean = false;
  showCust(){
      this.def = true;
      this.cust = true;
      this.data = false;
      this.rewards = false;
  }
  showData(){
      this.def = true;
      this.cust = false;
      this.data = true;
      this.rewards = false;
  }
  showRewards(){
      this.def = true;
      this.cust = false;
      this.data = false;
      this.rewards = true;
  }
  ngOnInit() {
    window.scrollTo(0, 0)
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {

    if (window.pageYOffset > 30) {
      let element2 = document.getElementById('fixed-right');
      element2.classList.add('top-0');

    } else {
      let element2 = document.getElementById('fixed-right');
      element2.classList.remove('top-0');
    }
  }
}
