
module.exports.sendEmail = (form) =>{
  Email.send({
      Host : "smtp.gmail.com",
      Username : "siteadmin@glyphgames.com",
      Password : "glyphweb2019",
      To : ['tyrone@glyphgames.com',"customersupport@glyphgames.com"],
      From : "siteadmin@glyphgames.com",
      Subject : "Contact Us",
      Body : `<b>Name:</b> ${form.name} <br> <b>Email</b>: ${form.email}<br> <b>Mobile</b>: ${form.mob}<br> <b>Message</b>:<br> ${form.msg}`
  }).then(
    message => alert('Succesfully sent!')
  ).catch(e=>{
    alert('Something went wrong!')
  })
}
