import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-home2',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent2 implements OnInit {
  public ngxScrollToOffset: number;
  @ViewChild('home', {static: false}) elementView: ElementRef;
  viewHeight: number;
  constructor(private router: Router) {
    this.ngxScrollToOffset = 300;
  }
  ngOnInit() {}
  ngAfterViewInit() {
    this.viewHeight = this.elementView.nativeElement.offsetHeight;
    window.scrollTo(0, this.viewHeight-74);
  }

}
